import { DataStore } from 'aws-amplify';
import { Course } from '../../models';

const filterDeletedItemsInList = (item) => (item.deletedAt('eq', null));

/**
 *
 * Lists all courses.
 * @return {*}
 */
const listCourses = async () => {
  const courses = await DataStore.query(
    Course,
    (andPredicatedCourse) => andPredicatedCourse
      .and(filterDeletedItemsInList)
      .and((course) => course.accessType('eq', 'PUBLIC')),
  );
  return courses;
};

/**
 *
 * fetches a single course by courseId
 * @param {*} id
 * @return {*}
 */
const fetchCourseById = async (id) => {
  const courses = (await DataStore.query(Course, (andPredicatedCourse) => andPredicatedCourse.and(
    (course) => course.id('eq', id),
  )));
  if (courses.length === 1) {
    return courses[0];
  }
  return null;
};

export {
  listCourses,
  fetchCourseById,
};
