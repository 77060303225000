import { navigate } from 'gatsby';
import { Auth, DataStore, Storage } from 'aws-amplify';
// eslint-disable-next-line import/no-cycle
import { updateUserBySub } from '../../DataStore/Services';
import { Role } from '../../../models';
import { CognitoUserWithAttributes, DatastoreUser } from '../../../types/commons';
import { toaster } from '../../../services/utils';
import { ProfileInfo } from '../../../context/Admin/AdminContext';

type Profile = ProfileInfo | DatastoreUser | {
  [key:string]: string,
}

/**
 *
 *
 * @param {*} roles
 * @return {*}
 */
const getUserRole = (roles: Role[]): Role | 'LEARNER' => {
  if (roles?.includes(Role.OWNER)) {
    return Role.OWNER;
  }
  if (roles?.includes(Role.ADMIN)) {
    return Role.ADMIN;
  }
  if (roles?.includes(Role.MANAGER)) {
    return Role.MANAGER;
  }
  return 'LEARNER';
};

/**
 *
 * fetches current user data from cognito
 */
const getCurrentUser = async (): Promise<any> => {
  const currentAuthUser = await Auth.currentAuthenticatedUser();
  return currentAuthUser;
};

/**
 * checks validity
 * for password update.
 *
 */
const validatePasswordFields = (
  oldPassword: string,
  newPassword: string,
): boolean => {
  let isValid = true;
  if (!oldPassword) {
    isValid = false;
    toaster('Please enter old password', 'error');
  } else if (!newPassword) {
    isValid = false;
    toaster('Please enter new password', 'error');
  } else if (oldPassword === newPassword) {
    isValid = false;
    toaster('Old password is the same as new password', 'error');
  } else if (newPassword.length < 8) {
    isValid = false;
    toaster('Password should have at least 8 characters', 'error');
  }
  return isValid;
};

/**
 *
 *
 * @param {*} user
 * @param {*} data
 */
const updateName = async (user: CognitoUserWithAttributes, data: Profile): Promise<void> => {
  try {
    // update cognito user name
    if (data?.firstName && data?.lastName) {
      const fullName = `${data.firstName} ${data.lastName}`;
      await Auth.updateUserAttributes(user, {
        name: fullName,
        'custom:first_name': data.firstName,
        'custom:last_name': data.lastName,
      });
      // update datastore user name
      await updateUserBySub(data.sub, data);
      toaster('Name has been changed');
    } else {
      toaster('Please Enter the name');
    }
  } catch (e) {
    toaster('Name update failed', 'error');
  }
};

/**
 *
 *
 * @param {*}
 * user,
 * oldPassword,
 * newPassword,
 *
 */
const updatePassword = async (
  user: CognitoUserWithAttributes,
  oldPassword: string,
  newPassword: string,
): Promise<void> => {
  try {
    await Auth.changePassword(user, oldPassword, newPassword);
    toaster('Password has been changed');
  } catch (e:any) {
    const errorMessage = e?.message || 'Password  update failed';
    toaster(errorMessage, 'error');
  }
};

/**
 *
 *
 * @param {*} userId
 * @param {*} file
 */
const updateUserImage = async (userId: string, file: File): Promise<void> => {
  try {
    await Storage.remove(userId);
    await Storage.put(userId, file, {
      contentType: file.type,
    });
  } catch (e) {
    console.log(e);
  }
};

/**
 *
 * logs the user out and
 * clears datastore
 */
const logout = async (showMessage = true, navigatePostLogout = true): Promise<void> => {
  try {
    await Auth.signOut();
    if (showMessage) {
      toaster('Logged out');
    }
    await DataStore.stop();
    await DataStore.clear();

    if (navigatePostLogout) {
      navigate('/login');
    }
  } catch (error) {
    console.log('error signing out: ', error);
  }
};

export {
  getUserRole,
  getCurrentUser,
  validatePasswordFields,
  updateName,
  updatePassword,
  updateUserImage,
  logout,
};
