import { DataStore } from 'aws-amplify';
import { Group, GroupMember, User } from '../../models';
import { toaster } from '../../services/utils';
import { getEnterpriseProfile } from '../GraphQL/Services';
import { getCurrentUser } from '../Shared/Services/AuthService';
import { fetchUserBySub, deleteGroupMembers } from './UserService';

/**
 *
 *
 * @param {*} name
 * @return {*}
 */
const fetchGroupByName = async (name) => {
  const groups = await DataStore.query(Group,
    (group) => group.name('eq', name).deletedAt('eq', null));
  if (groups.length === 1) {
    return groups[0];
  }
  return null;
};

/**
 *
 *
 * @param {*} name
 * @return {*}
 */
const createGroup = async (name) => {
  // hack to keep unique with name
  const existingGroup = await fetchGroupByName(name);
  if (existingGroup) {
    toaster('Group with same name already exist', 'error', 2);
    return null;
  }
  const group = await DataStore.save(new Group({
    name,
  }));
  return group;
};

/**
 *
 *
 * @param {*} id
 * @param {*} name
 * @return {*}
 */
const updateGroup = async (id, name) => {
  const groups = await DataStore.query(Group, (andPredicatedGroup) => andPredicatedGroup.and(
    (group) => group.id('eq', id).deletedAt('eq', null),
  ));

  const updatedGroup = await DataStore.save(
    Group.copyOf(groups[0], (updatedGroupEntry) => {
      // eslint-disable-next-line no-param-reassign
      updatedGroupEntry.name = name;
    }),
  );
  return updatedGroup;
};

/**
 *
 *
 * @return {*}
 */
const listGroups = async () => {
  const user = await getCurrentUser();
  const subId = user.attributes.sub;
  const id = user.attributes?.['custom:active_user'];
  const userRes = await fetchUserBySub(subId, id);
  const enterpriseId = userRes?.enterpriseID;
  const companyProfileInfo = await getEnterpriseProfile(enterpriseId);
  const groups = await DataStore.query(Group, (group) => group.enterpriseID('eq', companyProfileInfo.id).deletedAt('eq', null));
  return groups;
};

/**
 * lists all users in a single group
 *
 */
const listUsersInGroup = async (groupId) => {
  const members = await DataStore.query(GroupMember,
    (groupMember) => (groupMember.deletedAt('eq', null)));
  if (members.length) {
    const users = members.reduce((acc, cur) => {
      if (cur.group.id === groupId) {
        acc.push(cur.member);
      }
      return acc;
    }, []);
    return users;
  }
  return [];
};

/**
 *
 *
 * @param {*} id
 * @return {*}
 */
const deleteGroup = async (id) => {
  // TODO check impact on GroupMembers
  const groupToDelete = await DataStore.query(Group, (andPredicatedGroup) => andPredicatedGroup.and(
    (group) => group.id('eq', id).deletedAt('eq', null),
  ));

  const currentdate = new Date().toISOString();

  const deletedGroup = await DataStore.save(
    Group.copyOf(groupToDelete[0], (updateGroupEntry) => {
      Object.assign(updateGroupEntry, { deletedAt: currentdate });
    }),
  );

  // assign groupMembers deletedAt attr to null
  const groupMembersToDelete = await DataStore.query(GroupMember,
    (andPredicatedGroup) => andPredicatedGroup.and(
      (groupMember) => groupMember.group(
        (group) => group.id === id,
      ).deletedAt('eq', null),
    ));

  const deletedGroupMembers = await deleteGroupMembers(groupMembersToDelete);

  return { deletedGroup, deletedGroupMembers };
};

const removeUserFromGroup = async (id, sub) => {
  // fetch user from datastore
  const member = (await DataStore.query(User,
    (user) => user.sub('eq', sub).deletedAt('eq', null)))[0];
  // lookup for existing entries
  const groupMembers = (await DataStore.query(GroupMember, (groupMember) => groupMember.deletedAt('eq', null))).filter(
    (groupMember) => (groupMember.group.id === id && groupMember.member.id === member.id),
  );

  const deletedGroupMembers = await deleteGroupMembers(groupMembers);
  return deletedGroupMembers;
};

/**
 * listing groups for which the user is manager
 * @param {*} sub
 */
const listGroupsForManager = async (sub, id = null) => {
  const user = await fetchUserBySub(sub, id);

  // group ids where user is manager
  const groupIds = [];
  const groups = (await DataStore.query(GroupMember, ((groupMember) => (groupMember.deletedAt('eq', null))))).filter(
    (groupMember) => (groupMember.member.id === user.id && groupMember.isManager),
  ).map((groupMember) => {
    if (!groupIds.includes(groupMember.group.id)) {
      groupIds.push(groupMember.group.id);
      return groupMember.group;
    } return null;
  });
  return groups;
};

export {
  fetchGroupByName,
  createGroup,
  updateGroup,
  listGroups,
  listUsersInGroup,
  deleteGroup,
  removeUserFromGroup,
  listGroupsForManager,
};
