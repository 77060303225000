import { DataStore } from 'aws-amplify';
import {
  Course, CourseRegistration, CourseRegistrationStatus, Group,
} from '../../models';
import { listCourses } from './CourseService';

const filterDeletedItemsInList = (item) => (item.deletedAt('eq', null));

/**
 *
 * @param {*} id
 * @return {*}
 */
const fetchRegistrationById = async (id) => {
  const registration = await DataStore.query(CourseRegistration, id);
  const registrationWithCourse = await DataStore.query(Course, registration.courseID)
    .then((res) => ({
      ...registration,
      course: res,
    }));
  return registrationWithCourse;
};

/**
 *
 * @param {*} courseID
 * @return {*}
 */
const fetchRegistrationByCourseId = async (courseID) => {
  const registrations = await DataStore.query(CourseRegistration, (courseRegistration) => courseRegistration.courseID('eq', courseID).deletedAt('eq', null));
  return registrations;
};

/**
 * fetch user course registration
 * @param {*} sub
 */
const fetchRegistrationBySub = async (sub) => {
  const registration = await DataStore.query(CourseRegistration,
    (courseRegistration) => courseRegistration.userSub('eq', sub).deletedAt('eq', null));
  if (registration) {
    return registration;
  }
  return null;
};

/**
 * fetches the eligible registration
 * for a user by courseId
 *
 * @param {*} courseId
 */
const fetchEligibleRegistrationByCourseId = async (courseId, userId) => {
  const registrations = await DataStore.query(CourseRegistration,
    (andPredicatedRegistration) => andPredicatedRegistration.and(
      (courseRegistration) => courseRegistration.courseID('eq', courseId).deletedAt('eq', null),
    ));
  if (registrations.length) {
    const eligibleRegistrations = registrations
      .filter((registration) => registration.userID === userId);
    if (eligibleRegistrations.length) {
      return eligibleRegistrations[0];
    }
  }
  return null;
};

/**
 *
 * Lists all registrations.
 * @return {*}
 */
const listRegistrations = async () => {
  const courseRegistrations = await DataStore.query(CourseRegistration, filterDeletedItemsInList);
  const courses = await listCourses();
  const registrations = courseRegistrations.map((courseRegistration) => {
    const registration = { ...courseRegistration };
    for (let i = 0; i < courses.length; i += 1) {
      if (courses[i]?.id === courseRegistration?.courseID) {
        registration.course = courses[i];
      }
    }
    return registration;
  });
  if (registrations) {
    return registrations;
  }
  return [];
};

/**
 * lists all registrations by userId
 *
 */
const listLearnerRegistrations = async (userId) => {
  const courseRegistrations = await DataStore.query(CourseRegistration, filterDeletedItemsInList);
  const elgibleRegistrations = courseRegistrations
    .filter((registration) => (registration.userID === userId));
  return elgibleRegistrations;
};

/**
 * lists all registrations with status
 * 'APPROVED' or 'COMPLETED'
 *
 */
const listEligibleRegistrations = async (userId) => {
  const courseRegistrations = await DataStore.query(CourseRegistration, (registration) => (registration.status('ne', CourseRegistrationStatus.REQUESTED).deletedAt('eq', null)));
  if (courseRegistrations?.length) {
    const elgibleRegistrations = courseRegistrations
      .filter((registration) => (registration?.userID === userId));
    return elgibleRegistrations;
  }
  return [];
};

/**
 *
 * @param {*} id
 */
const deleteRegisteredCourse = async (id) => {
  const allCourses = await DataStore.query(CourseRegistration, filterDeletedItemsInList);
  if (allCourses) {
    const courseToDelete = allCourses.filter((course) => course.id === id);
    if (courseToDelete) {
      const currentdate = new Date().toISOString();
      const deletedCourse = await DataStore.save(
        Group.copyOf(courseToDelete[0], (updateCourseEntry) => {
          Object.assign(updateCourseEntry, { deletedAt: currentdate });
        }),
      );
      return deletedCourse;
    }
  }
  return null;
};

export {
  fetchRegistrationById,
  fetchRegistrationBySub,
  fetchEligibleRegistrationByCourseId,
  listRegistrations,
  listLearnerRegistrations,
  listEligibleRegistrations,
  deleteRegisteredCourse,
  fetchRegistrationByCourseId,
};
