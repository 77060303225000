import { DataStore, SortDirection } from 'aws-amplify';
import { CourseRegistrationActivity, CourseRegistrationActivityStatus } from '../../models';
import { toaster } from '../../services/utils';

/**
 *
 *
 * @param {*} courseRegistrationId
 * @return {*}
 */
const fetchCourseRegistrationActvitiesByRegistrationId = async (courseRegistrationId) => {
  const courseRegistrationActivities = await DataStore.query(CourseRegistrationActivity,
    (activity) => activity.courseRegistrationID('eq', courseRegistrationId));
  if (courseRegistrationActivities.length) {
    return courseRegistrationActivities;
  }
  return [];
};

/**
 * fetches all course registration activities
 *
 * @return {*}
 */
const fetchCourseRegistrationActvities = async () => {
  const courseRegistrationActivities = await DataStore.query(CourseRegistrationActivity,
    (activity) => activity.deletedAt('eq', null));
  if (courseRegistrationActivities.length) {
    return courseRegistrationActivities;
  }
  return [];
};

/**
 * fetch user course registration activity by taskId.
 *
 * @param {*} taskId
 */
const fetchCourseRegistrationActvityByTaskId = async (courseRegistrationId, taskId) => {
  const courseRegistrationActvity = await DataStore.query(CourseRegistrationActivity,
    (activity) => activity.courseRegistrationID('eq', courseRegistrationId).taskID('eq', taskId));
  if (courseRegistrationActvity.length) {
    return courseRegistrationActvity[0];
  }
  return null;
};

/**
 *
 * creates a new course registration activity.
 * @param {*} {
 *   courseRegistrationID,
 *   userID,
 *   taskID,
 * }
 * @return {*}
 */
const createCourseRegistrationActivity = async ({
  courseRegistrationID,
  courseID,
  user,
  taskID,
  taskType,
  status,
}) => {
  // hack to keep unique with task
  const exisitingActivity = await
  fetchCourseRegistrationActvityByTaskId(courseRegistrationID, taskID);
  if (exisitingActivity) {
    toaster('Activity with taskId already exist', 'error', 2);
    return null;
  }

  const activity = await DataStore.save(new CourseRegistrationActivity({
    courseRegistrationID,
    courseID,
    user,
    taskID,
    taskType,
    status,
    userSub: user?.sub,
  }));
  return activity;
};

/**
 * updates a registration activity.
 *
 * @param {*} taskID
 * @return {*}
 */
const updateCourseRegistrationActivityByTaskId = async ({
  courseRegistrationID,
  taskID,
  status,
}) => {
  const activity = await fetchCourseRegistrationActvityByTaskId(courseRegistrationID, taskID);
  const updatedCourseRegistrationActivity = await DataStore.save(
    CourseRegistrationActivity.copyOf(activity, (updatedActivity) => {
      Object.assign(updatedActivity, {
        status,
      });
    }),
  );

  return updatedCourseRegistrationActivity;
};

const updateCourseRegistrationActivityByTaskIdToReviewState = async ({
  courseRegistrationID,
  taskID,
  status,
}) => {
  // Only updating the activity to review state if the status is already in started
  const activity = await fetchCourseRegistrationActvityByTaskId(courseRegistrationID, taskID);
  const updatedCourseRegistrationActivity = await DataStore.save(
    CourseRegistrationActivity.copyOf(activity, (updatedActivity) => {
      Object.assign(updatedActivity, {
        status,
      });
    }),
    (currentActivity) => currentActivity.status('eq', CourseRegistrationActivityStatus.STARTED),
  );

  return updatedCourseRegistrationActivity;
};

/**
 * fetches all course registration activities for a user.
 *
 * @return {*}
 */
const fetchLastUpdatedCourseRegistrationActivityForUser = async (userId) => {
  const activities = await DataStore.query(CourseRegistrationActivity);
  if (activities.length) {
    const activitiesForUser = activities
      .filter((activity) => (activity?.user?.id === userId));
    if (activitiesForUser.length) {
      const lastUpdatedActivitiesForUser = activitiesForUser
        .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
      return lastUpdatedActivitiesForUser[0];
    }
    return null;
  }
  return null;
};

/**
 *
 * fetches the last updated course registration activity.
 * @param {*}
 * courseRegistrationID,
 *
 */
const fetchLastUpdatedCourseRegistrationActivityForRegistration = async (courseRegistrationID) => {
  const activities = (await DataStore.query(CourseRegistrationActivity, (activity) => activity.courseRegistrationID('eq', courseRegistrationID), {
    sort: (s) => s.updatedAt(SortDirection.DESCENDING),
  }));
  if (activities.length) {
    return activities[0];
  }
  return null;
};

/**
 *
 * @param {*} courseRegIds
 * @returns
 */
const fetchCompletedCourseRegistrationActiviesByCourseRegIds = async (courseRegIds) => {
  if (!courseRegIds.length) {
    return [];
  }
  const completedCourseRegistrationActivities = await DataStore
    .query(CourseRegistrationActivity, (courseRegistrationActivity) => courseRegistrationActivity
      .or((activity) => courseRegIds
        .reduce((acc, cur) => acc
          .and((predicate) => predicate
            .courseRegistrationID('eq', cur)
            .status('eq', CourseRegistrationActivityStatus.COMPLETED)),
        activity)));
  return completedCourseRegistrationActivities;
};
/**
 *
 *
 */
const fetchCourseRegistrationActvitiesByStatus = async (status) => {
  const activities = (await DataStore.query(CourseRegistrationActivity, (activity) => activity.status('eq', status)));
  if (activities.length) {
    return activities;
  }
  return [];
};

export {
  fetchCompletedCourseRegistrationActiviesByCourseRegIds,
  fetchCourseRegistrationActvitiesByRegistrationId,
  fetchCourseRegistrationActvityByTaskId,
  createCourseRegistrationActivity,
  updateCourseRegistrationActivityByTaskId,
  fetchLastUpdatedCourseRegistrationActivityForRegistration,
  fetchLastUpdatedCourseRegistrationActivityForUser,
  fetchCourseRegistrationActvitiesByStatus,
  fetchCourseRegistrationActvities,
  updateCourseRegistrationActivityByTaskIdToReviewState,
};
