import { DataStore, API } from 'aws-amplify';
import {
  GroupMember, Role, Status, User,
} from '../../models';
import { createUser as createUserGraphql } from '../../graphql/mutations';
import * as GraphQLApiService from '../GraphQL/Services';
import { getCurrentUser } from '../Shared/Services/AuthService';
import { toaster } from '../../services/utils';
import { getUser } from '../../graphql/queries';

/**
 *
 *
 * @param {*} sub
 * @return {*}
 */
const fetchUserBySub = async (sub, activeUserId = null) => {
  const users = (await DataStore.query(User,
    (user) => user.sub('eq', sub).deletedAt('eq', null)));
  if (users?.length > 0) {
    if (users?.length > 1) {
      if (activeUserId) {
        const activeUser = users.find((user) => (user.id === activeUserId));
        if (activeUser) return activeUser;
      }
    }
    return users[0];
  }
  return null;
};

/**
 *
 *
 * @param {*} sub
 * @return {*}
 */
const fetchUsersBySub = async (sub) => {
  const users = (await DataStore.query(User,
    (user) => user.sub('eq', sub).deletedAt('eq', null)));
  if (users.length) {
    return users;
  }
  return null;
};

/**
 *
 *
 * @param {*} sub
 * @param {*} {
 *   email, name = '', status = Status.CONFIRMED, roles = [], enabled = true,
 * }
 * @return {*}
 */
const createUser = async (sub, {
  email, name = '', status = Status.CONFIRMED, roles = [], enabled = true,
}) => {
  // hack to keep unique with sub
  const existingUser = await fetchUserBySub(sub);
  if (existingUser) {
    toaster('User with sub already exist', 'error', 2);
    return null;
  }
  const user = await API.graphql({
    query: createUserGraphql,
    variables: {
      input: {
        id: sub,
        sub,
        email,
        name,
        status,
        roles,
        enabled,
      },
    },
  });
  return user;
};

/**
 *
 *
 * @return {*}
 */
const listUsers = async () => {
  const currentUser = await getCurrentUser();
  const subId = currentUser.attributes.sub;
  const id = currentUser.attributes?.['custom:active_user'];
  const userDetails = await fetchUserBySub(subId, id);
  const enterpriseId = userDetails?.enterpriseID;
  const companyProfileInfo = await GraphQLApiService.getEnterpriseProfile(enterpriseId);
  const users = await DataStore.query(User, (user) => user.enterpriseID('eq', companyProfileInfo?.id).deletedAt('eq', null));
  return users;
};

/**
 *
 *
 * @param {*} groupIds
 * @return {*}
 */
const listUsersInGroups = async (groupIds) => {
  const users = [];
  const userSubs = [];
  if (groupIds) {
    const members = (await DataStore.query(GroupMember, ((groupMember) => (groupMember.deletedAt('eq', null)))));

    // selected group members
    for (let i = 0; i < members.length; i += 1) {
      if (groupIds.includes(members[i].group.id) && !(userSubs.includes(members[i].member?.sub))) {
        userSubs.push(members[i].member?.sub);
        users.push({
          email: members[i]?.member?.email,
          name: members[i]?.member?.name || '',
          ...members[i],
        });
      }
    }
  }
  return users;
};

/**
 *
 *
 * @param {*} [groupIds=null]
 * @return {*}
 */
const listPrivilegedUsers = async (groupIds = null) => {
  let users = [];
  if (groupIds) {
    const members = (await DataStore.query(GroupMember, ((groupMember) => (groupMember.deletedAt('eq', null)))));
    const userSubs = [];
    // selected group members
    for (let i = 0; i < members.length; i += 1) {
      if (
        groupIds.includes(members[i].group.id)
        && !(userSubs.includes(members[i].member?.sub))
        && (members[i].member?.roles.includes('MANAGER') || members[i].member?.roles.includes('ADMIN'))
      ) {
        userSubs.push(members[i].member?.sub);
        users.push({
          roles: members[i].member?.roles,
          name: members[i]?.member?.name || members[i]?.member?.email,
          ...members[i],
        });
      }
    }
  } else {
    const usersFilteredByRoles = await DataStore.query(User,
      (orPredicatedUser) => orPredicatedUser.or(
        (user) => user.roles('contains', Role.ADMIN).roles('contains', Role.MANAGER),
      ));
    users = usersFilteredByRoles.filter((user) => (user.deletedAt === null));
  }
  return users;
};

const fetchUserRole = async (sub) => {
  const usersFilteredByRoles = await DataStore.query(User,
    (orPredicatedUser) => orPredicatedUser.or(
      (user) => user.roles('contains', Role.ADMIN).roles('contains', Role.MANAGER),
    ));
  const userWithRole = usersFilteredByRoles.filter(
    (user) => (user.deletedAt === null && user.sub === sub),
  );
  return userWithRole;
};

// isManager when not passed or null will leave the current role untampered
// otherwise it should be boolean
// temp work around.. need to use datastore consistently across
const addUserToGroup = async (id, sub, isManager = null) => {
  // const user = await fetchUserBySub(sub);
  const user = await GraphQLApiService.getUserBySub(sub);
  const existingGroupMembership = await GraphQLApiService.getGroupMember(id, user?.id);
  let groupMember;
  // update when existing & role is changing
  if (existingGroupMembership) {
    if (isManager !== null) {
      groupMember = await GraphQLApiService.updateGroupMember(
        // eslint-disable-next-line no-underscore-dangle
        existingGroupMembership.id, existingGroupMembership._version, true, isManager || false,
      );
    } else {
      groupMember = existingGroupMembership;
    }
  } else {
    // create
    groupMember = await GraphQLApiService.createGroupMember(
      id, user.id, true, isManager || false, sub,
    );
  }
  return groupMember;
};

/**
 * soft delete group members (deletedAt attr to currentTime)
 * @param {*} groupMembersToDelete
 */
const deleteGroupMembers = async (groupMembersToDelete) => {
  const currentdate = new Date().toISOString();
  const deleteGroupMembersPromise = groupMembersToDelete.map((member) => (
    DataStore.save(GroupMember.copyOf(member, (updateGroupMemberEntry) => {
      Object.assign(updateGroupMemberEntry, { deletedAt: currentdate });
    }))));

  const deletedGroupMembers = await Promise.all(deleteGroupMembersPromise);
  return deletedGroupMembers;
};

/**
 *
 *
 * @param {*} id
 * @param {*} sub
 * @return {*}
 */
const removeUserFromGroup = async (id, sub) => {
  // fetch user from datastore
  const member = (await DataStore.query(User,
    (user) => user.sub('eq', sub).deletedAt('eq', null)))[0];
  // lookup for existing entries
  const groupMembers = (await DataStore.query(GroupMember, (groupMember) => groupMember.deletedAt('eq', null))).filter(
    (groupMember) => (groupMember.group.id === id && groupMember.member.id === member.id),
  );
  // for (let index = 0; index < groupMembers.length; index += 1) {
  //   const groupMember = groupMembers[index];
  //   // eslint-disable-next-line no-await-in-loop
  //   await DataStore.delete(groupMember);
  // }
  const deletedGroupMembers = await deleteGroupMembers(groupMembers);
  return deletedGroupMembers;
};

/**
 *
 *
 * @param {*} sub
 * @param {*} {
 *   email, name, status, roles, enabled,
 * }
 * @return {*}
 */
const updateUserBySub = async (sub, {
  id, email, name, status, roles, enabled, lastActive = null, firstName, lastName,
}) => {
  const user = await fetchUserBySub(sub, id);
  const updatedAttributes = {};
  if (email) {
    updatedAttributes.email = email;
  }
  if (name) {
    updatedAttributes.name = name;
  }
  if (firstName) {
    updatedAttributes.firstName = firstName;
  }
  if (lastName) {
    updatedAttributes.lastName = lastName;
  }
  if (roles) {
    updatedAttributes.roles = roles;
  }
  if (status) {
    updatedAttributes.status = status;
  }
  if (enabled !== null && enabled !== undefined) {
    updatedAttributes.enabled = enabled;
  }
  if (lastActive) {
    updatedAttributes.lastActive = lastActive;
  }
  const updatedUser = await DataStore.save(
    User.copyOf(user, (updateUserEntry) => {
      Object.assign(updateUserEntry, updatedAttributes);
    }),
  );
  return updatedUser;
};

/**
 *
 *
 * @param {*} sub
 * @return {*}
 */
const listGroupsWithRoleForUser = async (sub, id = null) => {
  const user = await fetchUserBySub(sub, id);
  const groups = (await DataStore.query(GroupMember, (groupMember) => groupMember.deletedAt('eq', null))).filter(
    (groupMember) => groupMember?.member?.id === user?.id,
  ).map((groupMember) => ({
    group: groupMember.group,
    isLearner: groupMember.isLearner,
    isManager: groupMember.isManager,
  }));
  return groups;
};

/**
 * lists users if manager logs in
 * @param {*} sub
 */
const listUsersForManager = async (sub, id = '') => {
  const user = await fetchUserBySub(sub, id);

  // group ids where user is manager
  const groupIds = [];
  const groups = (await DataStore.query(GroupMember, ((groupMember) => (groupMember.deletedAt('eq', null))))).filter(
    (groupMember) => (groupMember.member.id === user.id && groupMember.isManager),
  ).map((groupMember) => {
    if (!groupIds.includes(groupMember.group.id)) {
      groupIds.push(groupMember.group.id);
      return groupMember.group;
    } return null;
  });
  const userList = [];
  const userSub = [];

  // all members in the fetched group
  const members = (await DataStore.query(GroupMember, ((groupMember) => (groupMember.deletedAt('eq', null))))).filter(
    (groupMember) => groupIds.includes(groupMember.group.id),
  );
  // unique members
  for (let i = 0; i < members.length; i += 1) {
    if (!userSub.includes(members[i].member?.sub)) {
      userSub.push(members[i].member.sub);
      userList.push({
        key: members[i].id,
        ...members[i].member,
      });
    }
  }
  return {
    groups,
    userList,
  };
};

const listUsersWithRoleInGroup = async (id) => {
  const members = await DataStore.query(GroupMember,
    (groupMember) => (groupMember.deletedAt('eq', null)));

  const usersWithRoles = (members.filter(
    (member) => member.group.id === id,
  )).map((groupMember) => (
    {
      user: groupMember.member,
      isLearner: groupMember.isLearner,
      isManager: groupMember.isManager,
    }
  ));
  return usersWithRoles;
};

const getUserById = async (id) => {
  const response = await API.graphql({
    query: getUser,
    variables: {
      id,
    },
  });
  return response.data.getUser;
};

export {
  fetchUserBySub,
  fetchUsersBySub,
  createUser,
  listUsers,
  listUsersInGroups,
  listPrivilegedUsers,
  addUserToGroup,
  deleteGroupMembers,
  removeUserFromGroup,
  updateUserBySub,
  listGroupsWithRoleForUser,
  listUsersForManager,
  listUsersWithRoleInGroup,
  fetchUserRole,
  getUserById,
};
