import { DataStore } from 'aws-amplify';
import { AssignmentResponse } from '../../models';

/**
 *
 *
 * @param {*} assignmentResponses
 * @return {*}
 */
const getAssignmentResponseWithHeighestScore = (assignmentResponses) => {
  const assignmentResponseWithHeighestScore = assignmentResponses
    .reduce((prev, current) => ((prev.score > current.score) ? prev : current));
  return assignmentResponseWithHeighestScore;
};

/**
 *
 *
 * @param {*} courseRegistrationId
 * @param {*} taskId
 */
const fetchAssignmentResponseForCourseByTaskId = async (courseRegistrationId, taskId) => {
  const finalAssignmentReponses = await DataStore.query(AssignmentResponse,
    (response) => response.courseRegistrationID('eq', courseRegistrationId).taskID('eq', taskId));
  if (finalAssignmentReponses.length) {
    const assignmentResponseWithHeighestScore = getAssignmentResponseWithHeighestScore(
      finalAssignmentReponses,
    );
    return assignmentResponseWithHeighestScore;
  }
  return null;
};

/**
 *
 *
 * @param {*} userID
 */
const fetchAssignmentResponsesByUserId = async (userID) => {
  const finalAssignmentReponse = await DataStore.query(AssignmentResponse,
    (response) => response.userID('eq', userID));
  if (finalAssignmentReponse.length) {
    return finalAssignmentReponse;
  }
  return [];
};

export {
  fetchAssignmentResponseForCourseByTaskId,
  fetchAssignmentResponsesByUserId,
};
